:root {
  --font-sans: 'Inter', --apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
  Droid Sans, Helvetica Neue, sans-serif;
  --app-bg: var(--gray1);
  --cmdk-shadow: 0 16px 700px rgb(0 0 0 / 40%);

  --lowContrast: #ffffff;
  --highContrast: #000000;

  --gray1: hsl(0, 0%, 99%);
  --gray2: hsl(0, 0%, 97.3%);
  --gray3: hsl(0, 0%, 95.1%);
  --gray4: hsl(0, 0%, 93%);
  --gray5: hsl(0, 0%, 90.9%);
  --gray6: hsl(0, 0%, 88.7%);
  --gray7: hsl(0, 0%, 85.8%);
  --gray8: hsl(0, 0%, 78%);
  --gray9: hsl(0, 0%, 56.1%);
  --gray10: hsl(0, 0%, 52.3%);
  --gray11: hsl(0, 0%, 43.5%);
  --gray12: hsl(0, 0%, 9%);

  --grayA1: hsla(0, 0%, 0%, 0.012);
  --grayA2: hsla(0, 0%, 0%, 0.027);
  --grayA3: hsla(0, 0%, 0%, 0.047);
  --grayA4: hsla(0, 0%, 0%, 0.071);
  --grayA5: hsla(0, 0%, 0%, 0.09);
  --grayA6: hsla(0, 0%, 0%, 0.114);
  --grayA7: hsla(0, 0%, 0%, 0.141);
  --grayA8: hsla(0, 0%, 0%, 0.22);
  --grayA9: hsla(0, 0%, 0%, 0.439);
  --grayA10: hsla(0, 0%, 0%, 0.478);
  --grayA11: hsla(0, 0%, 0%, 0.565);
  --grayA12: hsla(0, 0%, 0%, 0.91);

  --blue1: hsl(206, 100%, 99.2%);
  --blue2: hsl(210, 100%, 98%);
  --blue3: hsl(209, 100%, 96.5%);
  --blue4: hsl(210, 98.8%, 94%);
  --blue5: hsl(209, 95%, 90.1%);
  --blue6: hsl(209, 81.2%, 84.5%);
  --blue7: hsl(208, 77.5%, 76.9%);
  --blue8: hsl(206, 81.9%, 65.3%);
  --blue9: hsl(206, 100%, 50%);
  --blue10: hsl(208, 100%, 47.3%);
  --blue11: hsl(211, 100%, 43.2%);
  --blue12: hsl(211, 100%, 15%);
}

.dark {
  --app-bg: var(--gray1);

  --lowContrast: #000000;
  --highContrast: #ffffff;

  --gray1: hsl(0, 0%, 8.5%);
  --gray2: hsl(0, 0%, 11%);
  --gray3: hsl(0, 0%, 13.6%);
  --gray4: hsl(0, 0%, 15.8%);
  --gray5: hsl(0, 0%, 17.9%);
  --gray6: hsl(0, 0%, 20.5%);
  --gray7: hsl(0, 0%, 24.3%);
  --gray8: hsl(0, 0%, 31.2%);
  --gray9: hsl(0, 0%, 43.9%);
  --gray10: hsl(0, 0%, 49.4%);
  --gray11: hsl(0, 0%, 62.8%);
  --gray12: hsl(0, 0%, 93%);

  --grayA1: hsla(0, 0%, 100%, 0);
  --grayA2: hsla(0, 0%, 100%, 0.026);
  --grayA3: hsla(0, 0%, 100%, 0.056);
  --grayA4: hsla(0, 0%, 100%, 0.077);
  --grayA5: hsla(0, 0%, 100%, 0.103);
  --grayA6: hsla(0, 0%, 100%, 0.129);
  --grayA7: hsla(0, 0%, 100%, 0.172);
  --grayA8: hsla(0, 0%, 100%, 0.249);
  --grayA9: hsla(0, 0%, 100%, 0.386);
  --grayA10: hsla(0, 0%, 100%, 0.446);
  --grayA11: hsla(0, 0%, 100%, 0.592);
  --grayA12: hsla(0, 0%, 100%, 0.923);

  --blue1: hsl(212, 35%, 9.2%);
  --blue2: hsl(216, 50%, 11.8%);
  --blue3: hsl(214, 59.4%, 15.3%);
  --blue4: hsl(214, 65.8%, 17.9%);
  --blue5: hsl(213, 71.2%, 20.2%);
  --blue6: hsl(212, 77.4%, 23.1%);
  --blue7: hsl(211, 85.1%, 27.4%);
  --blue8: hsl(211, 89.7%, 34.1%);
  --blue9: hsl(206, 100%, 50%);
  --blue10: hsl(209, 100%, 60.6%);
  --blue11: hsl(210, 100%, 66.1%);
  --blue12: hsl(206, 98%, 95.8%);
}
